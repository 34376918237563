import React, { FunctionComponent } from "react";
import "./style.scss";

const GreetingBlock: FunctionComponent = () => {
    return <div className="GreetingBlock">
        <div className="content">
            <div className="text">
                <div className="row">
                    Ваши <span className="purple">иновационные</span>
                </div>
                <div className="row">идеи.</div>
                <div className="row">
                    Наш <span className="coral">искрометный</span>
                </div>
                <div className="row">подход.</div>
            </div>
        </div>
    </div>
}

export default GreetingBlock;