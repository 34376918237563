import React, { FunctionComponent } from "react";
import "./style.scss";
import blank_img from "../../asserts/svg/blanks.svg";
import tag_img from "../../asserts/svg/tags.svg";

const PriceBlock: FunctionComponent = () => {
    return <div id="pricign" className="PriceBlock">
        <div className="content">
            <h1>Как мы работаем</h1>
            <div className="tiles-wrapper">
                <div className="tile">
                    <div className="icon">
                        <img src={tag_img} alt="bubble" />
                    </div>
                    <div className="text">
                        <div className="price">Выделенная команда</div>
                        <div className="description">
                            Предоставляем экспертов специально для
                            вашего проекта.
                            <br />
                            <br />
                            Вам больше не нужно переживать
                            о масштабировании своей команды.
                        </div>
                    </div>
                </div>
                <div className="tile">
                    <div className="icon">
                        <img src={blank_img} alt="bubble" />
                    </div>
                    <div className="text">
                        <div className="price">Разработка ПО</div>
                        <div className="description">
                            Управляем процессом разработки от
                            идеи до готового решения.
                            <br /><br />
                            Воплощаем мечты в реальные работающие
                            продукты, востребованные на рынке.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default PriceBlock;