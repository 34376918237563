import React, { FunctionComponent } from "react";
import "./style.scss";

const DocumentPage: FunctionComponent = () => {
    return <div className="documents">
        <div className="content">
            <h1>Справка</h1>
            <h2>Документы</h2>
            <ol style={{paddingLeft: 20}}>
                <li><a href="https://spark18.ru/docs/СОУТ.pdf">Специальная оценка условий труда (СОУТ)</a></li>
            </ol>
        </div>
    </div>
}

export default DocumentPage;