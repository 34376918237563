import React, { FunctionComponent, useLayoutEffect, useState } from "react";
import "./style.scss";
import f1_img from "../../asserts/svg/f1.svg";
import f2_img from "../../asserts/svg/f2.svg";
import f3_img from "../../asserts/svg/f3.svg";
import { NavLink, useNavigate } from "react-router-dom";

const mobileWidth = 680;

const BottomMenu: FunctionComponent = () => {
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileWidth);

    useLayoutEffect(() => {
        window.addEventListener("resize", resize);  
        
        setTimeout(() => {
            resize();        
        }, 100);

        setTimeout(() => {
            resize();        
        }, 1000);

        return () => {
            window.removeEventListener("resize", resize);
        }
    });

    const resize = () => {
        setIsMobile(window.innerWidth <= mobileWidth);
    }

    const goTo = (id: string) => {
        navigate("/");

        setTimeout(() => {
            const el = document.getElementById(id);
            if (el) {
                if (!isMobile)
                    el.scrollIntoView({behavior: 'smooth'});
                else {
                    const offset = -50;
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = el.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }
        }, 100);
    }

    return <div className="BottomMenu">
        <div className="column first">
            <img src={f1_img} alt="circle" />
            <ul>
                <li className="link" onClick={() => goTo("features")}>Сервисы</li>
                <li className="link" onClick={() => goTo("pricign")}>Как мы работаем</li>
                <li className="link" onClick={() => goTo("about")}>О нас</li>
            </ul>
        </div>
        <div className="column second">
            <img src={f2_img} alt="square" />
            <ul>
                <li>
                    <NavLink to="docs">
                        Справка
                    </NavLink>
                </li>
                <li>
                    ООО СПАРК
                </li>
                <li>
                    ИНН: 1840075719
                </li>
            </ul>
        </div>
        <div className="column third">
            <img src={f3_img} alt="triangle" />
            <div className="block">
                <div className="row">Телефон: +7(982)8361846</div>
                <div className="row">
                    Почта: <a href="mailto:info@spark.runp">
                        info@spark.run
                    </a>
                </div>
                <div className="row mail">Адрес: Улица К. Маркса, дом 227, Ижевск, УР, Россия, 426008</div>
            </div>
        </div>
    </div>
}

export default BottomMenu;