import React, { createRef, FunctionComponent, useLayoutEffect, useState } from "react";
import "./style.scss";
import TopMenu from "../../menus/topMenu";
import TopMenuMobile from "../../menus/topMenuMobile";
import { useNavigate } from "react-router-dom";
import { goToTop } from "../../misc/navigation";
import { ReactComponent as Logo } from '../../asserts/svg/logo.svg';

const mobileWidth = 680;

const MainHeader: FunctionComponent = () => {
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileWidth);

    const ref_header = createRef<HTMLDivElement>();

    useLayoutEffect(() => {
        window.addEventListener("scroll", onScroll);
        window.addEventListener("resize", resize);  
        
        setTimeout(() => {
            onScroll();
            resize();        
        }, 100);

        setTimeout(() => {
            onScroll();
            resize();        
        }, 1000);

        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("resize", resize);
        }
    });

    const onScroll = () => {
        const verticalPadding = window.innerWidth <= 1100 ? 20 : 40;
        const scrollY = window.innerWidth <= 1100 ? 50 : 100;

        if (window.scrollY <= scrollY) {
            if (ref_header.current) {
                ref_header.current.style.paddingTop = `${verticalPadding}px`;
                ref_header.current.style.paddingBottom = `${verticalPadding}px`;
            }
        }
        else {
            if (ref_header.current) {
                ref_header.current.style.paddingTop = "10px";
                ref_header.current.style.paddingBottom = "10px";
            }
        }
    }

    const resize = () => {
        setIsMobile(window.innerWidth <= mobileWidth);
    }

    const goToMain = () => {
        navigate("/");
        goToTop();
    }

    return <div ref={ref_header} className="MainHeader">
        <div className="logo" onClick={goToMain} >
            <Logo />
        </div>
        <div className="menu">
            {!isMobile
                ? <TopMenu />
                : <TopMenuMobile />
            }
        </div>
    </div>
}

export default MainHeader