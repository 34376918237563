import React, { useEffect } from "react";
import { FunctionComponent } from "react";
import { createBrowserRouter, useLocation } from "react-router-dom"; 
import MainLayout from "./layouts/mainLayout";
import DocumentPage from "./pages/document-page";
import ErrorPage from "./pages/errorPage";
import MainPage from "./pages/mainPage";
import PrivacyPolicyPage from "./pages/privacyPolicyPage";
import TermsPage from "./pages/termsPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <MainPage />
            },
            {
                path: "docs",
                element: <DocumentPage />
            },
        ]
    },
    {
        path: "*",
        element: <ErrorPage />
    }
]);

export const ScrollToTop: FunctionComponent = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}


export default router;