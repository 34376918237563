import React, { FunctionComponent } from "react";
import AboutBlock from "../../blocks/aboutBlock";
import FeaturesBlock from "../../blocks/featuresBlock";
import GreetingBlock from "../../blocks/greetingBlock";
import PriceBlock from "../../blocks/priceBlock";
import "./style.scss";

const MainPage: FunctionComponent = () => {
    return <div className="MainPage">
        <GreetingBlock />

        <FeaturesBlock />

        <PriceBlock />

        <AboutBlock />
    </div>
}

export default MainPage;