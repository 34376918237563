import React, { FunctionComponent, useState } from "react";
import "./style.scss";
import menu_img from "../../asserts/svg/menu.svg";
import close_img from "../../asserts/svg/close.svg";
import { useNavigate } from "react-router-dom";

const TopMenuMobile: FunctionComponent = () => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen((current) => !current);
    }

    const goTo = (id: string) => {
        navigate("/");
        setTimeout(() => {
            const el = document.getElementById(id);
            if (el) {
                setIsOpen(false);
                const offset = -50;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = el.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }, 100);
    }

    return <div className="TopMenuMobile">
        <img className="menu" src={isOpen ? close_img : menu_img} alt="menu" onClick={toggle} />
        <div className={`menuBlock${isOpen ? "" : " hide"}`}>
            <div className="menuItem" onClick={() => goTo("features")}>Сервисы</div>
            <div className="menuItem" onClick={() => goTo("pricign")}>Как мы работаем</div>
            <div className="menuItem" onClick={() => goTo("about")}>О нас</div>
        </div>
    </div>
}

export default TopMenuMobile;