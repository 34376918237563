import React, { createRef, FunctionComponent, useLayoutEffect } from "react";
import "./style.scss";
import desktop_img from "../../asserts/svg/desktop_feature.svg";
import mobile_img from "../../asserts/svg/mobile_feature.svg";
import web_img from "../../asserts/svg/web_feature.svg";
import startup_img from "../../asserts/svg/startup_feature.svg";
import uiux_img from "../../asserts/svg/uiux_feature.svg";
import complex_img from "../../asserts/svg/complex_feature.svg";

const FeaturesBlock: FunctionComponent = () => {
    const ref_text = createRef<HTMLDivElement>();
    const ref_border = createRef<HTMLDivElement>();

    useLayoutEffect(() => {
        window.addEventListener("resize", resize);
        setTimeout(() => {resize()}, 100);
        setTimeout(() => {resize()}, 1000);

        return () => {window.removeEventListener("resize", resize)}
    });

    const resize = () => {
        if (ref_border.current && ref_text.current) {
            ref_border.current.style.width = `${ref_text.current.clientWidth + 6}px`;
            ref_border.current.style.height = `${ref_text.current.clientHeight + 6}px`;
            ref_border.current.style.left = `calc(50% - ${ref_text.current.clientWidth / 2 - 4}px)`;
        }
    }

    return <div id="features" className="FeaturesBlock">
        <div className="content">
            <h1>Сервисы</h1>
            <div className="tiles">
                <div className="tile">
                    <div className="icon">
                        <img src={web_img} alt="recurrent shifts" />
                    </div>
                    <div className="textContent">
                        <h3>Веб – разработка</h3>
                        <div className="text">
                            Можете доверить нам создание любого  веб – приложения: от  бэк – офисных систем до интернет – магазинов
                            и веб – платформ.
                            Мы берем на себя всю работу, а вы экономите главные ресурсы – время и деньги.
                        </div>
                    </div>
                </div>

                <div className="tile">
                    <div className="icon">
                        <img src={mobile_img} alt="configurable reminders" />
                    </div>
                    <div className="textContent">
                        <h3>Мобильная разработка</h3>
                        <div className="text">
                            Специализируемся на создании программного обеспечения, которое решает реальные проблемы.
                            Разрабатываем для самых
                            популярных платформ - iOS и Android.
                        </div>
                    </div>
                </div>
                <div className="tile">
                    <div className="icon">
                        <img src={desktop_img} alt="configurable reminders" />
                    </div>
                    <div className="textContent">
                        <h3>Разработка настольных приложений</h3>
                        <div className="text">
                            Создаем настольные приложения, которые выводят бизнес на новый уровень.
                            Выполняем проекты любой сложности благодаря многолетнему опыту разработки корпоративных решений.
                        </div>
                    </div>
                </div>
                <div className="tile">
                    <div className="icon">
                        <img src={startup_img} alt="configurable reminders" />
                    </div>
                    <div className="textContent">
                        <h3>Стартапы</h3>
                        <div className="text">
                            Запускаем продукты, которые на 100% соответствуют запросам и потребностям рынка.
                            Сопровождаем вас на каждом этапе – от создания концепции до реализации готового продукта.
                        </div>
                    </div>
                </div>
                <div className="tile">
                    <div className="icon">
                        <img src={uiux_img} alt="configurable reminders" />
                    </div>
                    <div className="textContent">
                        <h3>UX/UI дизайн</h3>
                        <div className="text">
                            Используем лучшие практики пользовательского опыта и A/B – дизайн для создания красивых, удобных и современных интерфейсов.
                            Разрабатываем интерактивные эскизы для ваших идей, чтобы вы могли увидеть и почувствовать, как они работают.
                        </div>
                    </div>
                </div>

                <div className="tile">
                    <div className="icon">
                        <img src={complex_img} alt="reports" />
                    </div>
                    <div className="textContent">
                        <h3>Комплексные решения</h3>
                        <div className="text">
                            Наша команда не боится сложностей и готова к выполнению самых нетривиальных задач в области создания
                            программных продуктов.
                        </div>
                        <ul className="mt">
                            <li>Боты</li>
                            <li>Интеграции</li>
                            <li>Облачные решения</li>
                            <li>Машинное обучение и ИИ</li>
                            <li>DevOps</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default FeaturesBlock;