import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const TopMenu: FunctionComponent = () => {
    const navigate = useNavigate();

    const goTo = (id: string) => {
        navigate("/");
        setTimeout(() => {
            const el = document.getElementById(id);
            if (el)
                el.scrollIntoView({behavior: 'smooth'});
        }, 100);    
    }

    return <div className="TopMenu">
        <div className="menuItem" onClick={() => goTo("features")}>Сервисы</div>
        <div className="menuItem" onClick={() => goTo("pricign")}>Как мы работаем</div>
        <div className="menuItem" onClick={() => goTo("about")}>О нас</div>
    </div>
}

export default TopMenu;