import React, { FunctionComponent } from "react";
import "./style.scss";
import about_info_img from "../../asserts/svg/about_info.svg";


const AboutBlock: FunctionComponent = () => {
    return <div id="about" className="AboutBlock">
        <div className="content">
            <h1>О нас</h1>
            <div className="tiles">
                <div className="tile">
                    <div className="images">
                        <img src={about_info_img} alt="face 5" />
                    </div>
                    <div className="text">
                        Kаждый специалист Spark – эксперт в своей области. Мы максимально заинтересованы в результатах, которые вы получите,
                        потому что знаем, что наш успех зависит от вашего. Мы постоянно совершенствуемся и всегда готовы предоставить высочайшее
                        качество наших сервисов.
                        <br /><br />
                        Успешно работаем в различных отраслях: розничной торговле, медицине, документообороте, сфере услуг,
                        развлечениях, финансовом секторе и других.
                    </div>
                    <div className="buttons">
                        <a href="mailto:info@spark.run">
                            <button className="mail">
                                <div className="image"/>
                                <div className="text">Пишите нам на почту</div>
                            </button>
                        </a>

                        <a target="_blank" rel="noreferrer" href="https://t.me/shchepashcheps">
                            <button className="tg">
                                <div className="image"/>
                                <div className="text">Или в телеграм</div>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="cards">
                <div className="row">
                    <div className="card card5">
                        <div className="card-content">
                            <div className="card-title">5+</div>
                            <div className="card-text">Лет продуктивной работы в России и за ее пределами</div>
                        </div>
                        <div className="card-bg"></div>
                    </div>
                    <div className="card card100">
                        <div className="card-content">
                            <div className="card-title">100%</div>
                            <div className="card-text">Довольных клиентов</div>
                        </div>
                        <div className="card-bg"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="card card30">
                        <div className="card-content">
                            <div className="card-title">30+</div>
                            <div className="card-text">Успешных проектов разного масштаба и сложности</div>
                        </div>
                        <div className="card-bg"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default AboutBlock;