import React, { FunctionComponent } from "react";
import BottomMenu from "../../menus/bottomMenu";
import "./style.scss";

const MainFooter: FunctionComponent = () => {
    return <div className="MainFooter">
        <div className="content">
            <BottomMenu />
            <div className="right-block">
                <div className="rights first">© Spark, 2023</div>
                <div className="second"/>
                <div className="third"/>
            </div>
        </div>
    </div>
}

export default MainFooter;