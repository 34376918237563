import React, { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import MainFooter from "../../footers/mainFooter";
import MainHeader from "../../headers/mainHeader";
import { ScrollToTop } from "../../router";
import "./style.scss";

const MainLayout: FunctionComponent = () => {
    return <div id="top" className="MainLayout">
        <ScrollToTop />
        <MainHeader />
        <div className="outlet">
            <Outlet />
        </div>
        <MainFooter />
    </div>
}

export default MainLayout;